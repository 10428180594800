import { PaginatedCollection, Session, User } from '@/types'

import ApiService from './api_service'

export default {
  async index(query = {}): Promise<PaginatedCollection<User>> {
    const response = await ApiService.get('/admin/users', { params: query })
    return response.data
  },

  async get(id: number | string) {
    const response = await ApiService.get(`/admin/users/${id}`)
    return response.data
  },

  async create(user) {
    const response = await ApiService.post('/admin/users', { user: user })
    return response.data
  },

  async update(id: number | string, user) {
    const response = await ApiService.put(`/admin/users/${id}`, { user: user })
    return response.data
  },

  async destroy(id: number | string) {
    const response = await ApiService.delete(`/admin/users/${id}`)
    return response.data
  },

  async getCurrentSession(): Promise<Session> {
    const response = await ApiService.get(`/users/current-session`)
    return response.data
  },

  async signIn(params: { email: string; password: string }): Promise<User> {
    const response = await ApiService.post(`/users/sign_in`, { user: params })
    return response.data
  },

  async signUp(params: { email: string; password: string }): Promise<User> {
    const response = await ApiService.post(`/users`, { user: params })
    return response.data
  },

  async signOut(): Promise<void> {
    const response = await ApiService.delete(`/users/sign_out`)
    return response.data
  },

  async changePassword(currentPassword: string, newPassword: string): Promise<void> {
    const response = await ApiService.post(`/users/change-password`, {
      current_password: currentPassword,
      new_password: newPassword
    })
    return response.data
  },

  async signInAs(userId: number | string): Promise<Session> {
    const response = await ApiService.post(`/users/sign-in-as/${userId}`)
    return response.data
  }
}
