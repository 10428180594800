function escapeHtml(html) {
  const div = document.createElement('div')
  div.textContent = html
  return div.innerHTML
}

const DEFAULT_DURATION = 3000
const DEFAULT_VARIANT = 'primary'
const icons = {
  primary: 'info-circle',
  success: 'check2-circle',
  neutral: 'gear',
  warning: 'exclamation-triangle',
  danger: 'exclamation-octagon'
}

export function alert(message, variant = DEFAULT_VARIANT, icon = null, duration = DEFAULT_DURATION) {
  const alert = Object.assign(document.createElement('sl-alert'), {
    variant,
    closable: true,
    duration: duration,
    innerHTML: `
        <sl-icon name="${icon || icons[variant]}" slot="icon"></sl-icon>
        ${escapeHtml(message)}
      `
  })

  document.body.append(alert)
  return alert.toast()
}
